import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
const Index = lazy(() => import('./routes/index'));
const Portfolio = lazy(() => import('./routes/portfolio'));
const Contacto = lazy(() => import('./routes/contact'));
const Blog = lazy(() => import('./routes/blog'));


const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Index}/>
        <Route exact path="/portafolio" component={Portfolio}/>
        <Route exact path="/contacto" component={Contacto}/>
        <Route exact path="/blog" component={Blog}/>
      </Switch>
    </Suspense>
  </Router>
)

export default App;
